section.about {
  width: calc(100% - 160px);
  margin: auto;
  height: max-content;
  text-align: center;
}

section.about .skills h3 {
  margin-top: 100px;
  margin-bottom: 27px;
}

section.about p,
section.about span {
  font-size: 1rem;
  max-width: 663px; /* 663 */
  margin: auto;
}

section.about .skills p:nth-child(2),
section.about p:nth-child(4) {
  margin-top: 27px;
  margin-bottom: 27px;
}

section.about .skills .wrapper {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tech-item {
  display: inline-block;
}

section.about .software .items {
  display: flex;
  flex-direction: column;
}

section.about .resume {
  margin: auto;
  margin-top: 100px;
  margin-bottom: 150px;
}

section.about .resume a {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  background-color: lightgrey;
  border-radius: 1000px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.5s;
}

section.about .resume a svg {
  stroke: var(--text-color);
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

section.about .resume a:hover {
  background-color: var(--primary);
  transition: all 0.5s;
}

@media screen and (min-width: 530px) {
  .tech-item:not(.tech-item:last-child)::after {
    content: ' /';
  }
}

@media screen and (max-width: 1080px) {
  section.about p {
    min-width: 238px;
  }
}

@media screen and (max-width: 885px) {
  section.about p {
    width: 450px;
  }

  section.about .skills .wrapper div {
    width: 377px;
  }
}

@media screen and (max-width: 720px) {
  .page .container section.about {
    width: calc(100% - 80px);
  }
}

@media screen and (max-width: 530px) {
  section.about p,
  section.about span {
    width: 100%;
  }

  section.about p,
  section.about span,
  section.about h3,
  h2,
  .resume,
  .wrapper {
    text-align: left;
  }

  section.about .skills .wrapper {
    display: block;
    margin-bottom: 0;
  }

  section.about .skills .wrapper div {
    padding: 0;
  }
  section.about .tech-item {
    display: block;
  }

  section.about .skills h3 {
    margin-top: 50px;
  }

  .item {
    display: inline-block;
  }
}

@media screen and (max-width: 400px) {
  section.about {
    width: 100vw;
  }

  section.about p {
    width: 100%;
    min-width: unset;
  }
}

@media screen and (max-width: 375px) {
  .page .container section.about {
    width: calc(100% - 40px);
  }
}
